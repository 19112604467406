<template>
  <div class="login-container zxj-login-container">
    <header-menu> </header-menu>
    <div class="login-body">
      <div class="box-center">
        <div class="login-box">
          <div class="login-left">
            <!-- <div>
              <el-tooltip v-if="loginType==='wechat'" class="item" effect="dark" content="账号密码登录" placement="top-start">
                <i class="fa fa-id-card login-type" @click="loginType='account'"></i>
              </el-tooltip>
              <el-tooltip v-else class="item" effect="dark" content="微信扫码登录" placement="top-start">
                <i class="fa fa-weixin login-type" @click="loginType='wechat'"></i>
              </el-tooltip>
            </div> -->
          </div>
          <div v-if="loginType === 'wechat'" class="login-right-wechat">
            <div class="turnBox" @click="loginType = 'account'">
              <el-tooltip
                popper-class="my-tooltip"
                effect="dark"
                content="账号登录"
                placement="left"
              >
                <i class="iconfont icondiannao login-type"></i>
              </el-tooltip>
            </div>
            <div class="w-shadow"></div>
            <div class="wechat-wrap">
              <span class="login-type-title">微信登录</span>
              <span class="login-info">请使用微信【扫一扫】</span>
              <div class="qr-img" v-loading="loadingQrCode">
                <img class="qr-img" :src="`data:text/html;base64,${qrCode}`" />
              </div>
              <span class="login-info"> 关注【广东轻工职业技术学院】公众号完成登录</span>
            </div>
          </div>

          <div v-else class="login-right">
            <div class="turnBox" @click="loginType = 'wechat'">
              <el-tooltip
                popper-class="my-tooltip"
                effect="dark"
                content="扫码登录"
                placement="left"
              >
                <i class="iconfont iconerweima login-type"></i>
              </el-tooltip>
            </div>
            <div class="w-shadow"></div>
            <div class="login-title">
              <span class="system-welcome"
                >欢迎{{ isRegister ? "注册" : "登录" }}</span
              >
              <span class="system-name">广东轻工职业技术学院</span>
            </div>
            <div class="login-form">
              <div v-if="isRegister">
                <el-form
                  ref="formRegister"
                  :model="registerForm"
                  :rules="registerRules"
                >
                  <div class="row-input-s">
                    <el-form-item prop="ContactNumber">
                      <el-input
                        v-model="registerForm.ContactNumber"
                        autocomplete="new-password"
                        class="login-input"
                        placeholder="请输入手机号"
                        size="medium"
                      >
                        <i slot="prefix" class="fa fa-phone pl-5" />
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="row-input-s">
                    <el-form-item prop="VerifyCode" class="row-input">
                      <el-row :gutter="6">
                        <el-col :span="16">
                          <el-input
                            v-model="registerForm.VerifyCode"
                            autocomplete="new-password"
                            placeholder="请输入验证码"
                            @keyup.enter.native="onSubmit"
                            size="medium"
                          >
                            <i slot="prefix" class="fa fa-envelope pl-5" />
                          </el-input>
                        </el-col>
                        <el-col :span="8">
                          <el-button
                            class="greenBtn"
                            v-if="canSendCode"
                            type="primary"
                            plain
                            size="medium"
                            @click="onVerifyCode(registerForm.ContactNumber)"
                            :loading="securityLoading"
                            >{{
                              securityLoading ? "获取中..." : "获取验证码"
                            }}</el-button
                          >
                          <span
                            v-else
                            class="font-remark"
                            style="padding-left: 12px;"
                            >{{ countdown }}秒后重发</span
                          >
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                  <div class="row-input-s">
                    <el-form-item prop="Password">
                      <el-input
                        v-model="registerForm.Password"
                        class="login-input"
                        autocomplete="new-password"
                        placeholder="设置登录密码"
                        show-password
                        size="medium"
                      >
                        <i slot="prefix" class="fa fa-lock pl-5" />
                      </el-input>
                    </el-form-item>
                  </div>
                  <el-form-item class="row-input-s">
                    <el-button
                      type="primary"
                      :loading="registerLoading"
                      class="submit-btn"
                      @click="onRegister"
                      >注册{{ registerLoading ? "中" : "" }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div v-else>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="账号密码" name="first">
                    <el-form ref="form" :model="loginForm" :rules="rules">
                      <div class="row-input">
                        <el-form-item prop="Username">
                          <el-input
                            v-model="loginForm.Username"
                            class="login-input"
                            placeholder="请输入用户名"
                          >
                            <i slot="prefix" class="iconfont iconyonghu pl-5" />
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="row-input">
                        <el-form-item prop="Password" class="row-input">
                          <el-input
                            v-model="loginForm.Password"
                            placeholder="请输入密码"
                            show-password
                            @keyup.enter.native="onSubmit"
                          >
                            <i slot="prefix" class="iconfont iconmima1 pl-5" />
                          </el-input>
                        </el-form-item>
                      </div>
                      <el-form-item class="row-input">
                        <el-button
                          type="primary"
                          :loading="loginLoading"
                          class="submit-btn"
                          @click="onSubmit"
                          >登录{{ loginLoading ? "中" : "" }}</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane label="手机验证码" name="second">
                    <el-form
                      ref="formRegister"
                      :model="registerForm"
                      :rules="registerRules"
                    >
                      <div class="row-input">
                        <el-form-item prop="ContactNumber">
                          <el-input
                            v-model="registerForm.ContactNumber"
                            class="login-input"
                            placeholder="请输入手机号"
                          >
                            <i slot="prefix" class="fa fa-phone pl-5" />
                          </el-input>
                        </el-form-item>
                      </div>
                      <div class="row-input">
                        <el-form-item prop="VerifyCode" class="row-input">
                          <el-row :gutter="6">
                            <el-col :span="16">
                              <el-input
                                v-model="registerForm.VerifyCode"
                                placeholder="请输入验证码"
                                @keyup.enter.native="onSubmit"
                              >
                                <i slot="prefix" class="fa fa-envelope pl-5" />
                              </el-input>
                            </el-col>
                            <el-col :span="8">
                              <el-button
                                v-if="canSendCode"
                                class="greenBtn"
                                type="primary"
                                plain
                                @click="
                                  onVerifyCode(registerForm.ContactNumber)
                                "
                                :loading="securityLoading"
                              >
                                {{
                                  securityLoading ? "获取中..." : "获取验证码"
                                }}</el-button
                              >
                              <span
                                v-else
                                class="font-remark"
                                style="padding-left: 12px;"
                                >{{ countdown }}秒后重发</span
                              >
                            </el-col>
                          </el-row>
                        </el-form-item>
                      </div>
                      <el-form-item class="row-input">
                        <el-button
                          type="primary"
                          :loading="loginLoading"
                          class="submit-btn"
                          @click="onSubmitCode"
                          >登录{{ loginLoading ? "中" : "" }}
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  <!-- <el-tab-pane label="微信扫码" name="third">
                  
                </el-tab-pane>-->
                </el-tabs>
              </div>
            </div>
            <div class="login-switch">
              <div v-if="isRegister">
                <span style="color: #999">已有账号？</span>
                <span
                  style="color: #598DF3;cursor: pointer;"
                  @click="handleLogin"
                  >立即登录</span
                >
                <!-- <el-button type="text" @click="handleLogin">立即登录</el-button> -->
              </div>
              <div v-else>
                <span style="color: #999">没有账号？</span>
                <span
                  style="color: #598DF3;cursor: pointer;"
                  @click="handleRegister"
                  >现在注册</span
                >
                <!-- <el-button type="text" @click="handleRegister">现在注册</el-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="500px"
      :show-close="false"
      :before-close="handleCloseBind"
    >
      <span>为了您的账号安全，请您绑定手机号!</span>
      <el-form
        ref="formBind"
        :model="bindForm"
        :rules="bindRules"
        class="mt-20"
      >
        <div class="row-input">
          <el-form-item prop="Phone">
            <el-input
              v-model="bindForm.Phone"
              class="login-input"
              placeholder="请输入手机号"
              size="small"
            >
              <i slot="prefix" class="fa fa-phone pl-5" />
            </el-input>
          </el-form-item>
          <el-form-item prop="VerifyCode" class="row-input">
            <el-row :gutter="6">
              <el-col :span="16">
                <el-input
                  v-model="bindForm.VerifyCode"
                  placeholder="请输入验证码"
                >
                  <i slot="prefix" class="fa fa-envelope pl-5" />
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-button
                  v-if="canSendCode"
                  class="greenBtn"
                  type="primary"
                  plain
                  @click="onVerifyCode(bindForm.Phone)"
                  :loading="securityLoading"
                >
                  {{ securityLoading ? "获取中..." : "获取验证码" }}</el-button
                >
                <span v-else class="font-remark" style="padding-left: 12px;"
                  >{{ countdown }}秒后重发</span
                >
              </el-col>
            </el-row>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleBindPhone"
          :loading="bindLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getSecurityCode, registerAccount } from "@/api/account";
import HeaderMenu from "@/components/Common/HeaderMenu";
import {
  getQrCode,
  confirmLoginBySceneId,
  bindPhone
} from "@/api/wechatserver";

export default {
  components: { HeaderMenu },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        UserName: "",
        Password: "",
        LoginType: 0
      },
      valid: true,
      redirect: this.$route.query && this.$route.query.redirect,
      rules: {
        UserName: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      loginLoading: false,
      isRegister: false,
      registerForm: {
        ContactNumber: "",
        VerifyCode: "",
        EchoName: "",
        Password: ""
      },
      registerRules: {
        ContactNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" }
        ],
        EchoName: [{ required: true, message: "请输入名字", trigger: "blur" }],
        VerifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      bindForm: {
        Phone: "",
        Id: "",
        VerifyCode: ""
      },
      bindRules: {
        Phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" }
        ],
        VerifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      },
      registerLoading: false,
      securityLoading: false,
      canSendCode: true,
      countdown: 60,
      activeName: "first",
      loginType: "wechat",
      qrCode: "",
      loadingQrCode: true,
      timer: "",
      sceneId: "",
      dialogVisible: false,
      bindLoading: false,
      scanMsg: {}
    };
  },
  watch: {
    loginType(val) {
      if (val === "wechat") {
        getQrCode().then(res => {
          this.qrCode = res.Result.imgbase64;
          this.loadingQrCode = false;
          this.sceneId = res.Result.sceneId;
          this.timer = setInterval(this.getConfirmInfo, 3000);
        });
      } else {
        clearInterval(this.timer);
      }
    }
  },
  async created() {
    if (this.loginType === "wechat") {
      getQrCode().then(res => {
        this.qrCode = res.Result.imgbase64;
        this.loadingQrCode = false;
        this.sceneId = res.Result.sceneId;
        this.timer = setInterval(this.getConfirmInfo, 3000);
      });
    }
  },
  mounted() {
    if (this.$route.query.type && this.$route.query.type === "register") {
      this.isRegister = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions("user", ["login"]),
    async getConfirmInfo() {
      var res = await confirmLoginBySceneId(this.sceneId);
      this.scanMsg = res.Result;
      if (this.scanMsg.openId) {
        clearInterval(this.timer);
        try {
          var showDialog = false;
          //已存在
          if (this.scanMsg.userExist) {
            //判断手机号
            if (!this.scanMsg.bindedPhone) {
              showDialog = true;
            }
          } //不存在帐号，需要用手机号注册
          else {
            showDialog = true;
          } //未绑定手机号的弹窗处理
          if (showDialog) {
            this.dialogVisible = true;
          } else {
            //已存在用户且绑定了手机号直接登录
            this.loginLoading = true;
            var loginForm = {
              LoginType: 2,
              OpenId: this.scanMsg.openId
            };
            var loginRes = await this.login(loginForm);
            if (loginRes.token) {
              this.$router
                .replace({ path: this.redirect || "/" })
                .catch(err => err);
            }
            this.loginLoading = false;
          }
        } catch (err) {
          console.log(err);
          this.loginLoading = false;
        }
      }
    },
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loginLoading = true;
            this.loginForm.LoginType = 0;
            var loginRes = await this.login(this.loginForm);
            console.log(1111111111, loginRes);
            if (loginRes.token) {
              this.$router
                .replace({ path: this.redirect || "/" })
                .catch(err => err);
            }
            // this.$router.replace('/')
            this.loginLoading = false;
          } catch (err) {
            console.log(err);
            this.loginLoading = false;
          }
        } else {
          // this.canSubmit = true
          console.log("error submit!!");
          return false;
        }
      });
    },
    async onSubmitCode() {
      this.$refs.formRegister.validate(async valid => {
        if (valid) {
          try {
            this.loginLoading = true;
            this.loginForm.LoginType = 1;
            const loginData = {
              UserName: this.registerForm.ContactNumber,
              Password: this.registerForm.VerifyCode,
              LoginType: 1
            };
            await this.login(loginData);
            this.$router
              .replace({ path: this.redirect || "/" })
              .catch(err => err);
            // this.$router.replace('/')
            this.loginLoading = false;
          } catch (err) {
            console.log(err);
            this.loginLoading = false;
          }
        } else {
          // this.canSubmit = true
          console.log("error submit!!");
          return false;
        }
      });
    },
    async onRegister() {
      this.$refs.formRegister.validate(async valid => {
        if (valid) {
          try {
            this.registerLoading = true;
            console.log(this.registerForm);
            registerAccount(this.registerForm)
              .then(res => {
                this.registerLoading = false;
                this.$message({
                  message: "账号已注册成功！",
                  type: "success",
                  duration: 1000
                });
                this.isRegister = false;
                //注册成功后自动登录
                this.onSubmitCode();
              })
              .catch(err => {
                this.registerLoading = false;
              });
          } catch (err) {
            console.log(err);
            this.registerLoading = false;
          }
        } else {
          // this.canSubmit = true
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRegister() {
      this.isRegister = true;
    },
    handleLogin() {
      this.isRegister = false;
    },
    async onVerifyCode(phone) {
      if (!phone) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
          duration: 1000
        });
        return;
      } else if (!/^1\d{10}$/.test(phone)) {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
          duration: 1000
        });
        return;
      }

      this.securityLoading = true;
      try {
        const res = await getSecurityCode(phone);
        if (res.Result) {
          this.canSendCode = false;
          setTimeout(() => {
            this.canSendCode = true;
            this.countdown = 60;
            clearInterval(this.timer);
          }, 60 * 1000);
          this.timer = setInterval(() => {
            this.countdown--;
          }, 1000);
        } else {
          throw res.Message;
        }
        // console.log(res)
      } catch (err) {
        this.$message({
          message: err,
          type: "error",
          duration: 2000
        });
      } finally {
        this.securityLoading = false;
      }
    },
    handleClick(tab, event) {
      this.registerForm = {
        ContactNumber: "",
        VerifyCode: "",
        EchoName: "",
        Password: ""
      };
      this.loginForm = {
        UserName: "",
        Password: "",
        LoginType: 0
      };
    },
    handleBindPhone() {
      this.$refs.formBind.validate(async valid => {
        if (valid) {
          try {
            this.bindLoading = true;
            var postForm = {
              WechatId: this.scanMsg.openId,
              Phone: this.bindForm.Phone,
              UserExist: this.scanMsg.userExist,
              VerifyCode: this.bindForm.VerifyCode
            };
            var res = await bindPhone(postForm);
            if (res.Result) {
              this.bindLoading = false;
              this.$message({
                message: "您的账号已成功绑定手机号！",
                type: "success",
                duration: 1000
              });
              this.dialogVisible = false;
              //绑定后直接登录
              var loginForm = {
                LoginType: 2,
                OpenId: this.scanMsg.openId
              };
              this.loginLoading = true;
              var loginRes = await this.login(loginForm);
              if (loginRes.token) {
                this.$router
                  .replace({ path: this.redirect || "/" })
                  .catch(err => err);
              }
              this.loginLoading = false;
            } else {
              this.bindLoading = false;
              this.$message({
                message: res.Message,
                type: "error",
                duration: 2000
              });
            }
          } catch (err) {
            console.log(err);
            this.bindLoading = false;
          }
        } else {
          // this.canSubmit = true
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCloseBind() {
      // this.$router.replace({ path: this.redirect || "/" }).catch(err => err);
    }
  }
};
</script>

<style lang="scss" scoped>
// div {
// display: flex;
//  }
.login-container {
  display: flex;
  flex: 1;
  background-color: #f7f8fa;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
}

.div-header {
  box-shadow: unset;
}

.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #ffffff;
  //  background-color: #F7F8FA;
  // background: url("/static/images/bg_login.jpg") no-repeat;
  //  background-size: cover;
  min-height: calc(100vh - 68px);
  flex-direction: column;
}

.box-top {
  display: flex;
  flex: 1;
}

.box-center {
  display: flex;
  height: 500px;
}

.box-bottom {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.login-box {
  display: flex;
  width: 1100px;
  height: 500px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  flex-direction: row;
}

.login-left {
  display: flex;
  background: url("../../../../static/images/login_bgbox.png") no-repeat;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 668px;
  height: 500px;
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;
}

.login-right {
  display: flex;
  width: 432px;
  // height: 500px;
  flex-direction: column;
  padding: 72px 56px;
  box-sizing: border-box;
  position: relative;

  .w-shadow {
    background-color: #fff;
    width: 123px;
    height: 123px;
    position: absolute;
    top: 26px;
    right: 26px;
    transform: rotate(45deg);
  }
}

.login-right-wechat {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  box-sizing: border-box;

  .w-shadow {
    background-color: #fff;
    width: 123px;
    height: 123px;
    position: absolute;
    top: 26px;
    right: 26px;
    transform: rotate(45deg);
  }

  .wechat-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-type-title {
      font-size: 28px;
      color: #333333;
      margin-bottom: 8px;
    }

    .login-info {
      font-size: 16px;
      color: #666666;
    }

    .qr-img {
      width: 224px;
      height: 224px;
    }
  }
}

.turnBox {
  position: absolute;
  top: 0;
  right: 0;
  width: 88px;
  height: 88px;
  background-color: #598DF3;
  opacity: 0.5;
  border-radius: 0 5px 0 0;

  .login-type {
    color: #ffffff;
    font-size: 34px;
    cursor: pointer;
    float: right;
    box-sizing: content-box;
    padding: 13px;
  }

  &:hover {
    opacity: 1;
  }
}

.login-title {
  flex: 0.2;
  line-height: 40px;

  .system-welcome {
    font-size: 28px;
    color: #333333;
  }

  .system-name {
    font-size: 28px;
    font-weight: 600;
    color: #598DF3;
  }
}

.login-form {
  margin-top: 13px;
}

.login-switch {
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 14px;
}

.submit-btn {
  width: 100%;
  background-color: #598DF3;
  border: #598DF3;
  opacity: 0.6;
}

.submit-btn:hover {
  opacity: 1;
  background-color: #598DF3;
  border: #598DF3;
}

.login-bottom {
  display: flex;
  height: 60px;
  justify-content: center; //子元素在横轴上的对齐方式
}

.row-input {
  margin-bottom: 24px;
}

.row-button {
  margin-top: 60px;
}

.div-type {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sp-title {
  font-size: 18px;
  color: #598DF3;
}

.qr-wrap {
  display: flex;
  min-height: 165px;
}
</style>
<style lang="scss">
.my-tooltip {
  background: #598DF3 !important;
}
.my-tooltip[x-placement^="left"] .popper__arrow {
  border-left-color: #598DF3 !important;
}
.my-tooltip[x-placement^="left"] .popper__arrow:after {
  border-left-color: #598DF3;
}

.zxj-login-container {
  .el-tabs__nav-wrap::after {
    height: 4px;
    background-color: unset;
  }
  .el-tabs__item {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #999;
  }
  .el-tabs__item.is-active {
    font-size: 18px;
    font-weight: 700;
    color: #598DF3;
  }
  .el-tabs__item:hover {
    color: #598DF3;
  }
  .el-tabs__active-bar {
    height: 4px;
    background-color: #598DF3;
    border-radius: 2px;
  }
  .el-tabs__header {
    margin: 0 0 24px;
  }
  .el-input__inner {
    color: #333;
    display: inline-block;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    outline: none;
    padding: 0 15px;
    width: 100%;
  }
  .el-input__inner:focus {
    border-color: #598DF3;
  }
  .el-input__prefix .fa {
    font-size: 22px;
    padding-top: 12px;
    padding-left: 11px;
  }
  .el-input__prefix .iconfont {
    font-size: 20px;
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding-top: 2px;
    padding-left: 11px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 48px;
  }
  .el-input .el-input__clear {
    padding-top: 2px;
    font-size: 16px;
  }
  .el-button--primary.is-plain {
    width: 100px;
    height: 48px;
    color: #598DF3;
    background: #fff;
    border-color: #598DF3;
    padding: 12px 0px;
  }
  .el-button--primary.is-plain:hover {
    background: #598DF3;
    color: #fff;
    border-color: #598DF3;
  }
  .el-input--medium .el-input__inner {
    height: 48px;
    line-height: 48px;
  }
}
</style>
