import request from '@/utils/request'
const controller = '/WechatServer'

 
  
export function getQrCode() {
  return request({
    url: `${controller}/GetQrCode`,
    method: 'get',
  })
} 

export function confirmLoginBySceneId(sceneId) {
  return request({
    url: `${controller}/ConfirmLoginBySceneId`,
    method: 'get',
    params: {
      sceneId
    }
  })
}
export function confirmByWechatCode(code) {
  return request({
    url: `${controller}/ConfirmByWechatCode`,
    method: 'get',
    params: {
      code
    }
  })
}
export function bindPhone(data) {
  return request({
    url: `${controller}/BindPhone`,
    method: 'post',
    data
  })
}

