<template>
  <loginPC v-if="isPC"></loginPC>
  <!-- <loginMobile v-else></loginMobile> -->
</template>

<script>
import * as sysTool from "@/assets/js/systemTools";
import loginPC from "./loginPC";
// import loginMobile from "./loginMobile";
export default {
  name: "EditLivecourse",
  // components: { loginPC, loginMobile },
  components: { loginPC },
  data() {
    return {
      isPC: sysTool.getIsPC()
    };
  },
  mounted() {},
  methods: {}
};
</script>
