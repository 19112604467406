// import 'http://pv.sohu.com/cityjson?ie=utf-8'

export function getBrowser() {
  let ua = navigator.userAgent.toLocaleLowerCase();
  let browserType = null;
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = "IE";
  } else if (ua.match(/firefox/) != null) {
    browserType = "firefox";
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = "UC";
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = "opera";
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = "baidu";
  } else if (ua.match(/metasr/) != null) {
    browserType = "sougou";
  } else if (
    ua.match(/tencenttraveler/) != null ||
    ua.match(/qqbrowse/) != null
  ) {
    browserType = "QQ";
  } else if (ua.match(/maxthon/) != null) {
    browserType = "maxthon";
  } else if (ua.match(/chrome/) != null) {
    var is360 = _mime("type", "application/vnd.chromium.remoting-viewer");
    if (is360) {
      browserType = "360";
    } else {
      browserType = "chrome";
    }
  } else if (ua.match(/safari/) != null) {
    browserType = "Safari";
  } else {
    browserType = "others";
  }
  return browserType;
}

function _mime(option, value) {
  var mimeTypes = navigator.mimeTypes;
  for (var mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true;
    }
  }
  return false;
}

// get os
export function getOS() {
  let sUserAgent = navigator.userAgent.toLocaleLowerCase();
  if (sUserAgent.indexOf("android") > -1) return "Android";
  if (sUserAgent.indexOf("iphone") > -1) return "iPhone";
  if (sUserAgent.indexOf("symbianos") > -1) return "SymbianOS";
  if (sUserAgent.indexOf("windows phone") > -1) return "Windows Phone";
  if (sUserAgent.indexOf("ipad") > -1) return "iPad";
  if (sUserAgent.indexOf("ipod") > -1) return "iPod";
  let platform = navigator.platform.toLocaleLowerCase();
  let isWin = platform === "win32" || platform === "windows";
  let isMac =
    platform === "mac68k" ||
    platform === "macppc" ||
    platform === "macintosh" ||
    platform === "macintel";
  if (isMac) return "Mac";
  var isUnix = platform === "x11" && !isWin && !isMac;
  if (isUnix) return "Unix";
  var isLinux = String(platform).indexOf("linux") > -1;
  if (isLinux) return "Linux";
  if (isWin) return "Windows";
  return "others";
}
export function getType() {
  if (
    /AppleWebKit.*mobile/i.test(navigator.userAgent) ||
    /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
      navigator.userAgent
    )
  ) {
    if (window.location.href.indexOf("?mobile") < 0) {
      try {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          console.log("手机页面");
          return "手机页面";
        } else if (/iPad/i.test(navigator.userAgent)) {
          console.log("平板页面");
          return "平板页面";
        } else {
          console.log("其他移动端页面");
          return "其他移动端页面";
        }
      } catch (e) {}
    }
  } else {
    console.log("PC页面");
    return "PC页面";
  }
}
export function getIP() {
  return sessionStorage.getItem("ip");
}
export function getArea() {
  return sessionStorage.getItem("area");
}
export function getIsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array(
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  );
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
export function getIsWechat() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    console.log("iswx"); //是微信浏览器
    return true;
  } else {
    console.log("notwx"); //不是微信浏览器
    return false;
  }
}
