import request from '@/utils/request'
const controller='/Account'

export function getSecurityCode(phone) {
  return request({
    url: `${controller}/SendSecurityCode`,
    method: 'get',
    params: { phone }
  })
}

export function registerAccount(data) {
  return request({
    url: `${controller}/Resister`,
    method: 'post',
    data
  })
}